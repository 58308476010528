import * as React from 'react'
//import {Helmet} from "react-helmet"

//import {Link} from 'gatsby'
import Layout from '../components/layout/layout'
//import {StaticImage} from 'gatsby-plugin-image'
// import ImageGallery from 'react-image-gallery';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const pageTitle = "International Management Association IMA :: Welcome to IMA"

// const images = [
//     {
//         original: '/media/frontpage/hsbc.jpg',
//         thumbnail: '/media/frontpage/hsbc.jpg',
//                 originalClass: "bannerImageDiv"
//
//     },
//     {
//         original: '/media/frontpage/IMA_HSBC.jpg',
//         thumbnail: '/media/frontpage/IMA_HSBC.jpg',
//                 originalClass: "bannerImageDiv"
//
//     },
//     {
//         original: '/media/frontpage/Caring_Co_2011.jpg',
//         thumbnail: '/media/frontpage/Caring_Co_2011.jpg',
//         originalClass: "bannerImageDiv"
//     },
// ];

const IndexPage = () => {
    return (
        <Layout pageTitle={pageTitle}>


            <div className="span-23 last" style={{height: "400px", width:"910px", overflow: "hidden", marginTop: "0"}}>
                {/*<ImageGallery items={images} showBullets={true} showNav={false} lazyLoad={true} showThumbnail={false}*/}
                {/*              useTranslate3D={false} autoPlay={false} slideInterval={4000}/>*/}
                {/*<img src="/media/frontpage/hsbc.jpg" border="0"/>*/}

                {/*<img src="/media/frontpage/IMA_HSBC.jpg" border="0"/>*/}

                {/*<img src="/media/frontpage/Caring_Co_2011.jpg" border="0"/>*/}
<Carousel autoPlay={true} statusFormatter={() => ""}>
                <div>
                    <img src="/media/frontpage/hsbc.jpg" />
                </div>
                <div>
                    <img src="/media/frontpage/IMA_HSBC.jpg" />
                </div>
                <div>
                    <img src="/media/frontpage/Caring_Co_2011.jpg" />
                </div>
            </Carousel>
            </div>

            <div className="span-23  last" style={{height: "83px", marginTop: "7px"}}>
                <div className="span-16"><img src="/lookandfeel/images/caringcompany.jpg" border="0"
                                              style={{marginLeft: "30px", marginTop: "10px"}}/></div>
                <div className="span-7 last" style={{backgroundColor: "#732466", height: "75px"}}>
                    <div id="frontpagequicklinks">
                        <i><u>For Registered Members</u></i><br/>
                        <a href="https://www.hopkins.edu.hk/" target="_blank">IMA Classroom</a>
                    </div>
                </div>
            </div>

            <div className="span-23 last" id="frontpageThreeColumns">
                <div className="span-1">&nbsp;</div>
                <div className="span-7">
                    <h3 className="darkpurple smalltitle">WELCOME TO IMA</h3>

                    <p>International Management Association (IMA) was established in 1999 and is specialized in
                        providing professional education and services to adult learners and companies in the
                        territory.</p>

                    <p>Being an advocate of education and life-long learning, IMA was established with the mission
                        to promote the development and the application of knowledge, and the maturation of intellect
                        and character through academic and professional training programs. <br/><a href="/about/"
                                                                                                   style={{
                                                                                                       color: "#ff0000",
                                                                                                       fontStyle: "italic"
                                                                                                   }}>See
                            more...</a></p>

                </div>
                <div className="span-1">&nbsp;</div>

                <div className="span-6">
                    <h3 className="darkpurple smalltitle">SERVICE PROGRAM</h3>


                    <p>From providing facilities rental to help organizing business events, IMA offers a range of
                        services to meet our clients’ needs. IMA caters to help our clients to do more with less
                        time and effort so as to maximize their performance output.</p>

                    <p>Some of the companies IMA closely works with include:<br/>

                        <ul>
                            <li><a href="http://cn.bwc-cm.com/">BWC-Capital Market</a></li>
                            <li><a href="http://www.hopkins.edu.hk/">Hopkins Group</a></li>
                            <li>LandPower International</li>
                        </ul>

                    </p>

                </div>
                <div className="span-1">&nbsp;</div>

                <div className="span-6  last">

                    <h3 className="darkpurple smalltitle">CONTACT US</h3>


                    <b>INTERNATIONAL MANAGEMENT ASSOCIATION<br/></b>
                    <br/>
                    Suite 505, Tower 1, South Seas Center<br/>
                    75 Mody Road, TST East, Hong Kong<br/>
                    Tel: 3111-7111<br/>
                    Fax: 3188-5035<br/>
                    Email: information@ima.hk<br/>
                    <p>&nbsp;</p>
                    <img src="/lookandfeel/images/ima_old_logo.gif" border="0"/>


                </div>

            </div>

        </Layout>
    )
}
// Step 3: Export your component
export default IndexPage
